import React from "react"

import { Helmet } from "react-helmet"

import { Container, Row, Col } from "react-bootstrap"
import LayoutEs from "../components/layoutEs"

const InfoEs = () => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "es-section info-section" }} />
      <LayoutEs>
        <Container className="desc-contain">
          <Row>
            <Col>
              <p className="intro-address">
                Calle Ventosa 28, 28005, Madrid Spain
              </p>
              <p className="desc">
                <span className="desc-subhead">GENERAL:</span>
                <ul>
                  <li>
                    Piso exterior totalmente reformado y listo para entrar a
                    vivir en el barrio de Palacio.
                  </li>
                  <li>
                    Amueblada y cocina completamente equipada, aire
                    acondicionado, 2 habitaciones, un baño (57 m2).
                  </li>
                </ul>
              </p>
              <p className="desc">
                <span className="desc-subhead">CARACTERÍSTICAS:</span>
                <ul>
                  <li>Vivienda de 62m2 totalmente reformada</li>
                  <li>Exterior en finca con ascensor</li>
                  <li>2 Dormitorios</li>
                  <li>1 Baños</li>
                  <li>AACC frío/calor en todas las estancias.</li>
                  <li>Cocina totalmente equipada.</li>
                  <li>Amueblado y equipado al detalle</li>
                </ul>
              </p>
              <p className="desc">
                <span className="desc-subhead">CONDICIONES DE ALQUILER:</span>
                <ul>
                  <li>1 mes de fianza</li>
                  <li>Acreditar solvencia económica (contrato, nominas.)</li>
                  <li>
                    Gasto por suministros a cargo del inquilino. IBI y gasto de
                    comunidad recae sobre el propietario.
                  </li>
                  <li>Mensualidad: 1190 Euros</li>
                </ul>
              </p>
              <p className="desc">
                <span className="desc-subhead">DESCRIPCIÓN:</span>
                <br />
                Vivienda rehabilitada en 2019 de forma integral por el estudio
                de arquitectura y construcción GMT+ Architecture &amp; Design.
                Se ha aprovechado al máximo el espacio para convertirlo en un
                piso cómodo y acogedor. Dispone de una habitación principal con
                armarios empotrados. La cocina está equipada con todos los
                electrodomésticos. El salón y el segundo dormitorio, separados
                por una estructura de cristal para dar amplitud a las estancias,
                tienen dos grandes ventanales con vistas a la calle Ventosa. De
                hecho, todas las estancias tienen ventanas, lo que proporciona
                muchísima luminosidad.
              </p>
              <p className="desc">
                <span className="desc-subhead">LA FINCA Y EL BARRIO:</span>
                <br />
                Un cuidado edificio de 1921 sito en la calle Ventosa,
                encorsetada entre la Gran Vía de San Francisco y la Ronda de
                Segovia, a pocos metros de la Puerta de Toledo. Pertenece al
                distrito de Palacio, un barrio repleto de historia y vida
                social. Rodeado de puntos de interés cultural, comercios y
                restaurantes. Cerca del parque de las Vistillas y de los
                Jardines del Palacio Real. Una ubicación perfecta tanto para un
                uso residencial, como turístico.
              </p>
            </Col>
          </Row>
        </Container>
      </LayoutEs>
    </>
  )
}

export default InfoEs
